'use client';

import { useState } from 'react';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import { getBrazeFlag } from 'harness-react';
import { BRAZE_NO_PURCHASE_MODAL, BRAZE_OUT_OF_MARKET_MODAL } from 'braze';
import { trackBrazeUser } from 'braze-react';
import { validEmail, GEOLOCATION_ALLOWED, GEOLOCATION_COMING_SOON, X_GEO_LOCATION_STATUS } from 'utils';
import { getConsentPurposes, NO_PURCHASE, OTHER_COUNTRY } from 'onetrust';
import { getCookie } from 'cookies-next';
import logger from 'logger';
import postConsent from '../../utils/onetrust/postConsent';
import SignupStatusMessage from './signupStatusMessage';

export default function TextfieldEntry({
  brazeConfig,
  emailSuccessMessage,
  emailErrorMessage,
  buttonText,
  brazeCustomUserAttributes,
  brazeSignupSource,
}) {
  const geoLocationStatus = getCookie(X_GEO_LOCATION_STATUS) || GEOLOCATION_ALLOWED;
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false); // postConsent error
  const [isSuccess, setIsSuccess] = useState(false); // postConsent success
  const collectionPoint = geoLocationStatus === GEOLOCATION_ALLOWED ? NO_PURCHASE : OTHER_COUNTRY;

  const brazeEnabled = getBrazeFlag();

  const createBrazeUser = async (customerEmail, signUpSource) => {
    const { externalId } = await fetch(`/api/marketing/user/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: customerEmail,
        signUpSource,
      }),
    }).then((res) => res.json());

    return externalId;
  };

  const getSignUpSource = (customUserAttributes) => {
    if (Object.keys(customUserAttributes).length) {
      return customUserAttributes?.type;
    }
    return (
      brazeSignupSource ||
      (geoLocationStatus === GEOLOCATION_COMING_SOON ? BRAZE_OUT_OF_MARKET_MODAL : BRAZE_NO_PURCHASE_MODAL)
    );
  };

  const resetState = () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
  };

  const setErrorState = () => {
    setIsError(true);
    setIsLoading(false);
  };

  const setSuccessState = () => {
    setIsSuccess(true);
    setIsLoading(false);
  };

  const onSignUp = async () => {
    resetState();

    if (!validEmail(email)) {
      setErrorState();
      return;
    }

    if (brazeEnabled) {
      try {
        const externalId = await createBrazeUser(email, getSignUpSource(brazeCustomUserAttributes));

        if (externalId != null) {
          trackBrazeUser(externalId, brazeCustomUserAttributes);

          const consentPurposes = getConsentPurposes(brazeConfig.LINGO_ENV, collectionPoint);
          await postConsent(collectionPoint, consentPurposes, externalId);
          setSuccessState();
        } else {
          logger.error('error getting external id from braze, could not post consent');
          setErrorState();
        }
      } catch (error) {
        logger.error(error);
        setErrorState();
      }
    }
  };

  return (
    <>
      <div className='relative'>
        <input
          className='signup-email-input relative z-10 w-full h-10 px-4 rounded'
          type='email'
          name='Email'
          onChange={(e) => {
            setEmail(e.target.value);
            setIsError(false);
          }}
          placeholder='Email address'
        />
        <button
          type='submit'
          disabled={isSuccess}
          className='button-dark disabled:bg-gray-100 disabled:text-gray-600 m-0 h-8 py-2 px-4 rounded absolute top-1 right-1 z-20 transition-all duration-700'
          data-analytics-location={LOCATION_PATHS.signup}
          data-analytics-action={buttonText}
          data-testid='signup-button'
          onClick={onSignUp}
        >
          {isLoading ? <span className='mx-4'>...</span> : buttonText}
        </button>
      </div>
      <SignupStatusMessage
        successMessage={emailSuccessMessage}
        errorMessage={emailErrorMessage}
        isSuccess={isSuccess}
        isError={isError}
      />
    </>
  );
}
